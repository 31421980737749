import {questionFactory} from "src/entities/project/survey/question-factory";

export class Survey {
  constructor(survey) {
    this.id = survey.id;
    this.title = survey.title;
    this.description = survey.description;
    this.language = survey.language;
    this.show_previous_button = survey.show_previous_button;
    this.ip_block = survey.ip_block;
    this.is_active = survey.is_active;
    this.inactive_survey_message = survey.inactive_survey_message;
    this.end_page_message = survey.end_page_message;
    this.is_published = survey.is_published;
    this.flow_id = survey.flow_id;
  }
}

export class Block {
  constructor(block) {
    this.id = block.id;
    this.title = block.title;
    this.index = block.index;
  }
}

export class Page {
  constructor(page, blockId) {
    this.id = page.id;
    this.index = page.index;
    this.blockId = blockId;
  }
}

export class Question {
  constructor(question, blockId, pageId) {
    this.id = question.id;
    this.label = question.label;
    this.text = question.text;
    this.type = question.type;
    this.index = question.index;
    this.is_random = question.is_random;
    this.display_logics = question.display_logics;
    this.skip_logics = question.skip_logics;
    this.blockId = blockId;
    this.pageId = pageId;

    Object.assign(this, questionFactory(question));
  }
}

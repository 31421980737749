export const arTranslations = {
  mySurveys: "استبياناتي",
  newSurvey: "استطلاع جديد",
  findparticipants: "إيجاد المشاركين",
  settingcategory: "إعداد الفئة",

  tableHeaders: {
    title: "عنوان",
    questions: "الأسئلة",
    responses: "الردود",
    completion: "الانتهاء",
    created: "تاريخ الإنشاء",
    updated: "تاريخ التحديث",
    actions: "الإجراءات",
  },
  prev: "زر السابق",
  next: "Next Button",
  noDataFound: "لا توجد بيانات",
  settings: {
    surveyTitle: "عنوان الاستبيان",
    live: "مباشر",
  },
  buttons: {
    downloadDraftExcel: "تحميل ملف إكسل المسودة",
  },
  importExcelButtonLabel: "استيراد من ملف إكسل",
  importExcelDialogTitle: "استيراد ملف إكسل",
  importExcelNameLabel: "الاسم",
  importFileButtonLabel: "استيراد الملف",
  createSurveyButtonLabel: "إنشاء",
  createSurveyDialogTitle: "إنشاء استبيان",
  nameInputLabel: "الاسم",
  createButtonLabel: "إنشاء",
  cancelButtonLabel: "إلغاء",
  copyLink: "نسخ الرابط",
  copySurvey: "نسخ الاستبيان",
  delete: "حذف",
  deleteSurveyTitle: "حذف الاستبيان",
  DeleteSurveyConfirmation:
    " سيتم حذف الاستبيان  بشكل نهائي هل أنت متأكد ؟ {{surveyTitle}}",

  cancel: "إلغاء",
  sections: {
    survey: "استبيان",
    preview: "معاينة",
    distributions: "توزيعات",
    results: "النتائج",
    analyticsDashboard: "لوحة التحكم التحليلية",
  },
  basicSettings: {
    title: "الإعدادات الأساسية",
    language: "اللغة",
    languageDescription:
      "نوصي باختيار اللغة التي كتبت فيها استبيانك. سيتم عرض بيانات الاستجابة أيضًا بهذه اللغة",
    previousButton: "زر السابق",
    previousButtonDescription: "تمكين/تعطيل زر السابق.",
    ipBlock: "حظر الآي بي",
    ipBlockDescription: " IP حظر الاستجابات المتعددة من نفس عنوان ",
  },
  writeSomething: "اكتب شيئًا",
  unavailableSurveyMessage: "رسالة الاستبيان غير المتاحة",
  buttonLabels: {
    update: "تحديث",
    reset: "إعادة تعيين",
  },
  blockSettings: {
    editBlock: "تعديل المحور",
    displayLogicWarning:
      "محاولة استخدام منطق العرض والترتيب العشوائي في نفس المحور . قد لا يعمل  كما هو متوقع",
    randomizeQuestions: "ترتيب الأسئلة بشكل عشوائي",
    addPageBreak: "إضافة فاصل  لجميع الأسئلة",
    makeAllRequired: "جعل جميع الأسئلة إلزامية",
  },
  editor: {
    endPageLabel: "صفحة النهاية",
    createBlockTooltip: "إنشاء محور سؤال",
    editBlockTooltip: "تحرير محور السؤال",
    deleteBlockTooltip: "حذف محور السؤال",
    blockDeletionConfirmation: "سيتم حذف محور السؤال  بشكل دائم. هل أنت متأكد؟",
  },

  downloadResults: "تحميل النتائج",
  resetSurvey: "إعادة تعيين الاستبيان",
  ignoreRequire: "تجاهل الطلب",
  ignorePreviewData: "تجاهل بيانات المعاينة",
  questionLabel: "تسمية السؤال",
  duplicate: "تكرار",
  shuffleQuestion: "تبديل الخيارات بشكل  عشوائي",
  deleteQuestion: "حذف السؤال",
  addOption: "إضافة خيار",

  label: "عنوان السؤال الافتراضي",
  choice1: "خيار 1",
  choice2: "خيار 2",
  choice3: "خيار 3",
  defaultQuestion: "عنوان السؤال الافتراضي",
  save: "حفظ",
  reset: "إعادة تعيين",

  EditQuestion: "تحرير السؤال",
  QuestionType: "نوع السؤال",
  Text: "نص",
  MultipleChoice: "اختيار متعدد",
  Settings: "الإعدادات",
  Behaviours: "السلوكيات",
  ShortText: "نص قصير",
  TextGraphic: "نص / رسوم",
  SurveyQuestion: "سؤال الاستبيان",
  EmbeddedDataField: "حقل البيانات المضمنة",
  EditQuestionTextDialogTitle: "تحرير نص السؤال",
  PlaceholderWriteSomething: "اكتب شيئًا",
  CancelButton: "إلغاء",

  SaveButton: "حفظ",
  RequiredLabel: "مطلوب",
  ContentTypeLabel: "نوع المحتوى",
  TextLabel: "نص",
  NumberLabel: "رقم",
  EmailAddressLabel: "عنوان البريد الإلكتروني",
  RequiredQuestionError: "هذا السؤال مطلوب",
  textInputTypeError: "الإدخال النصي غير صالح",
  integerInputTypeError: "الإدخال الرقمي غير صالح.",
  emailInputTypeError: "الإيميل البريدي غير صالح",
  DisplayLogic: "منطق العرض",
  DownloadQRCode: "    تحميل رمز QR ",
  CopyLink: "نسخ الرابط",
  AddElement: "إضافة عنصر",
  Link: "الرابط",
  QRCode: "QR  رمز ",
  CreateQuestion: "انشاء السؤال",
  AddPageBreak: " إضافة فاصل صفحة",
  LogoutButton: "تسجيل الخروج",
  AddBlock: "اضافة محور",
  AddBranch: "إضافة فرع",
  successfullyUpdated: "تم التحديث بنجاح",

  builder: "البناء",
  surveyFlow: "تدفق الاستطلاع",
  surveyOptions: "خيارات الاستطلاع",
  design: "التصميم",

  auth: {
    login: "تسجيل الدخول",
    register: "تسجيل",
    emailAddress: "البريد الإلكتروني",
    password: "كلمة المرور",
    continue: "متابعة",
    forgotPassword: "هل نسيت كلمة المرور؟",
    noAccount: "ليس لديك حساب؟",
  },

  designTab: {
    logo: "الشعار",
    general: "عام",
    background: "الخلفية",
    style: "أسلوب",
    motion: "حركة",
    similars: {
      left: "يسار",
      center: "مركز",
      right: "يمين",
      top: "أعلى",
      bottom: "أسفل",
    },
    logoStyles: {
      logoImage: "صورة الشعار",
      change: "تغيير",
      placement: "وضع",
      verticalPlacement: "وضع عمودي",
      maxHeight: "الارتفاع الأقصى",
      maxWidth: "العرض الأقصى",
    },
    generalStyles: {
      nextButtonText: "نص زر التالي",
      previousButtonText: "نص زر السابق",
      progressBar: "شريط التقدم",
      none: "لا شيء",
      withText: "مع نص",
      withoutText: "بدون نص",
      progressBarPlacement: "وضع شريط التقدم",
      progressBarVerticalPlacement: "وضع شريط التقدم العمودي",
    },
    styleStyles: {
      primaryColor: "اللون الأساسي",
      secondaryColor: "اللون الثانوي",
      font: "الخط",
      questionFontSize: "حجم خط السؤال (بكسل)",
      answersFontSize: "حجم خط الإجابات (بكسل)",
    },
    backgroundStyles: {
      backgroundColor: "لون الخلفية",
    },
    motionStyles: {
      animationType: "نوع الحركة",
      slide: "انزلاق",
      fade: "تلاشي",
      grow: "تكبير",
      zoom: "تكبير",
      collapse: "طي",
      slideAnimationDirection: "اتجاه حركة الانزلاق",
    },
    previewSide: {
      mySurvey: "استطلاعي",
      sampleSurvey: "استطلاع عينة",
      viewDesktop: "عرض سطح المكتب",
      viewMobile: "عرض الجوال",
      selectSurvey: "اختر الاستطلاع",
    },
  },
};
